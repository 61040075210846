import React from 'react';

const Svg7 = ({ svgColor}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.243" height="19.242" viewBox="0 0 19.243 19.242">
            <g id="Icon_feather-arrow-up-right" data-name="Icon feather-arrow-up-right" transform="translate(-8.379 -8.379)">
                <path id="Path_280" data-name="Path 280" d="M10.5,25.5l15-15" fill="none" className={svgColor}  strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                <path id="Path_281" data-name="Path 281" d="M10.5,10.5h15v15" fill="none"  className={svgColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            </g>
        </svg>
    )
}
export default Svg7;