import React from "react"
import plusGreen from "../../../../../../../static/assets/plus-green.png"
import * as styles from "./cardRightText.module.scss"

const CardRightText = ({ title, cardsCheckboxItems, paragraph, cardsSmallText, isBuyPage, imageLeft, imageRight }) => {
  return (
    <div className={`${styles.mainContainer} ${isBuyPage && styles.isBuyPage}`}>
      <div className={`${styles.cardRightTitle} ${isBuyPage && styles.isBuyPage}`}>{title}</div>
      <div className={`${styles.cardRightBorder} ${isBuyPage && styles.isBuyPage}`} />

      {isBuyPage ? (
        <div style={{ paddingBottom: 15 }}>
          {cardsCheckboxItems?.map((item, index) => (
            <div className={styles.content} key={index}>
              <img alt="" src={plusGreen} />
              <span className={styles.text}>{item.checkboxItem}</span>
            </div>
          ))}

          <div className={styles.paragraphContent}>
            <div className={styles.cardRightContentBuy}>{paragraph}</div>
            <span>{cardsSmallText}</span>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.cardRightContent}>{paragraph}</div>
          <div className={styles.mobileOrangeTop}>
            <div className={styles.mobileOrangeTopTextContainer}>
              <h2 className={styles.mobileOrangeTopTitle}>{title}</h2>
              <div className={styles.mobileOrangeTopGrayLine}/>
              <p className={styles.mobileOrangeTopParagraph}>{paragraph}</p>
            </div>
          </div>
        </div>
      )}

      <div className={styles.buttonWrapper}>
        <div className={styles.sellLogosTablet}>
          <div className={styles.logoWrapper}>
            <img src={imageLeft} alt="credentials" className={styles.sellImg} />
          </div>
          <div className={styles.logoWrapper}>
            <img src={imageRight} alt="credentials" className={styles.estImg} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardRightText
