// extracted by mini-css-extract-plugin
export var whycbsBackground = "whycbs-module--whycbsBackground--2US2H";
export var container = "whycbs-module--container--CYHA0";
export var titleContainer = "whycbs-module--titleContainer--3CYuh";
export var title = "whycbs-module--title--3jxBK";
export var subtitle = "whycbs-module--subtitle--wF3Mf";
export var borderLine = "whycbs-module--borderLine--3Vf73";
export var contentContainer = "whycbs-module--contentContainer--37HTg";
export var itemContainer = "whycbs-module--itemContainer--2EfGj";
export var imageWrapper = "whycbs-module--imageWrapper--mmlUg";
export var cardImage = "whycbs-module--cardImage--COpTx";
export var itemTitle = "whycbs-module--itemTitle--37sKl";
export var itemText = "whycbs-module--itemText--3xmn0";