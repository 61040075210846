import React from "react"
import PropTypes from "prop-types"
import TestimonialsItem from "../../TestimonialsItem"
import doubleQuotes from "../../../templates/avveckla-aktiebolag/assets/images/doublequotes.svg"
import * as styles from "./multiLayer.module.scss"

const MultiLayer = ({
                      testimonialsQuote,
                      testimonialsImage,
                      testimonialsList,
                      testimonialsLogo,
                      testimonialsLogoUrl,
                      isBuyPage
}) => {

  return (
    <div className={styles.backgroundLayer}>
      <div className={styles.darkGreenTriangle} />
      <div className={styles.backgroundImg}>
        <div className={styles.contentContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.imageWrapper}>
              <img
                src={testimonialsImage}
                alt="person"
                className={isBuyPage ? styles.leftMan : styles.leftGirl}
              />
            </div>
            <div className={styles.rightText}>
              <div className={styles.textWrapper}>
                <img
                  src={doubleQuotes}
                  alt="doubleQuotesleft"
                  className={styles.quotesLeft}
                />
                {testimonialsQuote}
                <img
                  src={doubleQuotes}
                  alt="doubleQuotesright"
                  className={styles.quotesRight}
                />
              </div>
              <div>
                <a
                  href={testimonialsLogoUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={testimonialsLogo}
                    alt="ucSigill"
                    className={styles.ucSigillLogo}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.testimonialsContainer}>
            {testimonialsList &&
              testimonialsList.map((item, index) => (
                <TestimonialsItem
                  text={item.text}
                  author={item.author}
                  position={item.position}
                  key={index}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

MultiLayer.propTypes = {
  testimonialsMock: PropTypes.object,
  testimonialsQuote: PropTypes.string,
}

export default MultiLayer
