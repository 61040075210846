import React from 'react';
import { Link } from "gatsby"
import CustomButton from '../../CustomButton'
import * as styles from './quickAdministration.module.scss';

const QuickAdministration = ({
                               quickAdministrationTitle,
                               quickAdministrationSubtitle,
                               quickAdministrationFirstParagraph,
                               quickAdministrationLinkText,
                               quickAdministrationLinkUrl,
                               quickAdministrationSecondParagraph,
                               quickAdministrationButtonText,
                               quickAdministrationButtonUrl,
                               quickAdministrationBelowButton,
                               quickAdministrationImageRightOfButton,
                               quickAdministrationImageRight,
                               isBuyPage }) => {

    return (
        <div className={styles.administrationBackground}>
            <div className={styles.backgroundImg}>
                <div className={styles.container}>
                    <div className={styles.leftContainer}>
                        <div className={styles.title}>
                            {quickAdministrationTitle}
                        </div>
                        <div className={styles.borderLine} />
                        <div className={styles.subtitle}>
                            {quickAdministrationSubtitle}
                        </div>
                        <div className={styles.paragraph}>
                            {quickAdministrationFirstParagraph}
                            {!isBuyPage && (
                                <Link to={quickAdministrationLinkUrl}
                                    className={styles.textLink}>
                                    {quickAdministrationLinkText}
                                </Link>)}
                        </div>
                        <div className={styles.paragraph}>
                            {quickAdministrationSecondParagraph}
                        </div>
                        <div className={styles.buttonContainer}>
                            <div className={styles.buttonWrapper}>
                                <CustomButton
                                    link={`${process.env.GATSBY_HOST_URL}${quickAdministrationButtonUrl}`}
                                    text={quickAdministrationButtonText}
                                    isBuySell={true}
                                    lightGreen={true}
                                    fullWidth={true}
                                    externalLink
                                />
                            </div>
                            <div>
                              <span className={styles.buttonRightText}>{quickAdministrationBelowButton}</span>
                              <div className={styles.buttonRightTextQuentingImageContainer}>
                                <img src={quickAdministrationImageRightOfButton} alt={quickAdministrationBelowButton} className={styles.buttonRightTextQuentingImage} />
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div >
                        <img src={quickAdministrationImageRight} className={styles.illustration} alt="illustration" />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickAdministration;
