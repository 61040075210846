// extracted by mini-css-extract-plugin
export var administrationBackground = "quickAdministration-module--administrationBackground--2yswR";
export var backgroundImg = "quickAdministration-module--backgroundImg--1k376";
export var container = "quickAdministration-module--container--jRkp_";
export var leftContainer = "quickAdministration-module--leftContainer--15GNi";
export var title = "quickAdministration-module--title--3Nxkj";
export var borderLine = "quickAdministration-module--borderLine--3lY4D";
export var subtitle = "quickAdministration-module--subtitle--3Yaem";
export var paragraph = "quickAdministration-module--paragraph--3MKOW";
export var textLink = "quickAdministration-module--textLink--1GNYG";
export var buttonContainer = "quickAdministration-module--buttonContainer--3Tt-U";
export var buttonRightText = "quickAdministration-module--buttonRightText--10wkd";
export var buttonWrapper = "quickAdministration-module--buttonWrapper--2HHYU";
export var buttonRightTextQuentingImageContainer = "quickAdministration-module--buttonRightTextQuentingImageContainer--Pm7H4";
export var buttonRightTextQuentingImage = "quickAdministration-module--buttonRightTextQuentingImage--srqNM";
export var rightContainer = "quickAdministration-module--rightContainer--PeFIv";
export var illustration = "quickAdministration-module--illustration--2Umb3";
export var leftIconContainer = "quickAdministration-module--leftIconContainer--3PJYa";
export var rightIconContainer = "quickAdministration-module--rightIconContainer--D53nj";
export var iconText = "quickAdministration-module--iconText--2YgZx";