import React from "react"
import PropTypes from "prop-types"
import check from "../../../../../../static/assets/check.png"
import plus from "../../../../../../static/assets/plus-square.png"

import * as styles from "./card.module.scss"
import CustomButton from "../../../../CustomButton"

const Card = ({
                cards,
                subtitle,
                isBuyPage,
                buttonLink,
                cardsOrangeCardTitle,
                cardsOrangeCardListItems,
                cardsButtonText,
                cardsButtonUrl,
                cardsTextNextToButton,
  }) => {
  return (
    <div className={styles.card}>
      {/* ============== Buy page ============== */}
      {isBuyPage && (
        <>
          <div className={styles.title}>{cardsOrangeCardTitle}</div>

          {cardsOrangeCardListItems && cardsOrangeCardListItems.map((item, index) => (
              <div className={styles.paragraph} key={index}>
                <img src={plus} alt="plus" className={styles.plusIcon} />
                <span className={styles.text}>{item.cardsOrangeCardListItem}</span>
              </div>
            ))}

          <div className={styles.buttonContainer}>
            <CustomButton
              link={`${process.env.GATSBY_HOST_URL}${cardsButtonUrl}`}
              text={cardsButtonText}
              whiteBg={true}
              customStyle={styles.buttonBuy}
              externalLink
            />
            <div className={styles.rightTextBuyPage}>{cardsTextNextToButton}</div>
          </div>
        </>
      )}
      {/* ============== Sell page Svgs ============== */}
      {!isBuyPage && (
        <>
          {cards?.map((item, index) => (
            <div className={styles.checkbox} key={index}>
              <img alt="checkbox" src={check} className={styles.checkboxImage}/>
              <span className={styles.text}>{item.title}</span>
            </div>
          ))}

          <div className={styles.buttonContainer}>
            <CustomButton
              link={`${process.env.GATSBY_HOST_URL}${buttonLink}`}
              text={cardsButtonText}
              whiteBg={true}
              customStyle={styles.button}
              externalLink
            />
          </div>
        </>
      )}

      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  svg: PropTypes.number,
  isBuyPage: PropTypes.bool,
}

export default Card
