import React from 'react';
import * as styles from './goldBorder.module.scss';

const GoldBorder = () => {

    return (
        <div className={styles.sellBorderLine} />
    )
}

export default GoldBorder;