// extracted by mini-css-extract-plugin
export var background = "hero-module--background--3prb5";
export var contentContainer = "hero-module--contentContainer--3RGtO";
export var leftContainer = "hero-module--leftContainer--1ivyH";
export var slogan = "hero-module--slogan--3XmOk";
export var title = "hero-module--title--2LC60";
export var borderLine = "hero-module--borderLine--60O_l";
export var subtitle = "hero-module--subtitle--2PKhM";
export var greenSubtitle = "hero-module--greenSubtitle--1xG4M";
export var paragraph = "hero-module--paragraph--22il-";
export var buttonContainer = "hero-module--buttonContainer--1lzPe";
export var buttonRightTextMobile = "hero-module--buttonRightTextMobile--3FxEs";
export var buttonRightTextQuentingImageContainer = "hero-module--buttonRightTextQuentingImageContainer--26sDu";
export var buttonRightTextQuentingImage = "hero-module--buttonRightTextQuentingImage--1jFD0";
export var extraPadding = "hero-module--extraPadding--2xd2F";
export var sellLogos = "hero-module--sellLogos--274Nt";
export var sellImg = "hero-module--sellImg--2Vd8y";
export var rightContainer = "hero-module--rightContainer--1uoQy";
export var rightImagesContainer = "hero-module--rightImagesContainer--3m30I";
export var rightImage = "hero-module--rightImage--3b_2G";
export var imageAnimate = "hero-module--imageAnimate--1gEdI";
export var sellGirl = "hero-module--sellGirl---TrPF";
export var gridRepeat = "hero-module--gridRepeat--271v0";
export var sloganMobile = "hero-module--sloganMobile--2XVqF";
export var logosBackground = "hero-module--logosBackground--2gfhF";
export var sellLogosTablet = "hero-module--sellLogosTablet--3EzxN";
export var estImg = "hero-module--estImg--1dLIG";
export var logoWrapper = "hero-module--logoWrapper--mAvyh";