import React from "react"
import CustomButton from "../../CustomButton"
import grid from "../../../templates/avveckla-aktiebolag/assets/images/gridRepeat.svg"
import classNames from "classnames"
import * as styles from "./hero.module.scss"
import NewlineText from "../../../utils/NewlineText"
import PropTypes from "prop-types"

const Hero = ({
  isBuyPage,
  sloganMobile,
  slogan,
  title,
  subtitle,
  greenSubtitle,
  paragraph,
  heroMainButtonText,
  heroMainButtonUrl,
  heroButtonRightTextMobile,
  second_paragraph,
  img,
  heroCursiveTextImage,
  heroTabletLogoLeft,
  heroTabletLogoRight,
}) => {
  return (
    <React.Fragment>
      <div className={styles.background}>
        <div className={styles.contentContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.slogan}>{slogan}</div>
            <div className={styles.title}>
              <NewlineText text={title} />
            </div>
            <div className={styles.borderLine} />

            {isBuyPage && (
              <div className={styles.subtitle}>
                <NewlineText text={subtitle} />
              </div>
            )}

            <div className={styles.paragraph}>
              <NewlineText text={paragraph} />
            </div>

            {isBuyPage && (
              <React.Fragment>
                <div className={styles.greenSubtitle}>{greenSubtitle}</div>
                <div className={styles.paragraph}>
                  <NewlineText text={second_paragraph} />
                </div>
              </React.Fragment>
            )}

            <div className={classNames(styles.buttonContainer)}>
              <CustomButton
                link={`${process.env.GATSBY_HOST_URL}${heroMainButtonUrl}`}
                text={heroMainButtonText}
                isBuySell={true}
                lightGreenMobile={true}
                externalLink
              />
              <div>
                <span className={styles.buttonRightTextMobile}>{heroButtonRightTextMobile}</span>
                <div className={styles.buttonRightTextQuentingImageContainer}>
                  <img src={heroCursiveTextImage} alt={heroButtonRightTextMobile} className={styles.buttonRightTextQuentingImage} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.sloganMobile}>
              <NewlineText text={sloganMobile} />
            </div>
            <div className={styles.rightImagesContainer}>
              <div className={styles.rightImage}>
                <img src={img} alt="professional" className={styles.sellGirl} />
              </div>
              <div className={styles.gridRepeat}>
                <img src={grid} alt="grid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ========== TABLET LOGOS ========== */}
      <div className={styles.logosBackground}>
        <div className={styles.sellLogosTablet}>
          <div className={styles.logoWrapper}>
            <img src={heroTabletLogoLeft} alt="credentials" className={styles.sellImg} />
          </div>
          <div className={styles.logoWrapper}>
            <img src={heroTabletLogoRight} alt="credentials" className={styles.estImg} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Hero.defaultProps = {
  isBuyPage: false,
}

Hero.propTypes = {
  isBuyPage: PropTypes.bool,
  sloganMobile: PropTypes.string,
  slogan: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  paragraph: PropTypes.string,
  heroButtonRightTextMobile: PropTypes.string,
  heroTabletLogoLeft: PropTypes.string,
  heroTabletLogoRight: PropTypes.string,
}

export default Hero
