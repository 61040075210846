// extracted by mini-css-extract-plugin
export var quickLiquidationBackground = "quickLiquidation-module--quickLiquidationBackground--3_ktI";
export var quickLiquidationContainer = "quickLiquidation-module--quickLiquidationContainer--BjDcp";
export var leftContainer = "quickLiquidation-module--leftContainer--3t7Wu";
export var title = "quickLiquidation-module--title--1he27";
export var firstParagraph = "quickLiquidation-module--firstParagraph--1TtMv";
export var greenParagraph = "quickLiquidation-module--greenParagraph--2Nrw8";
export var thirdParagraph = "quickLiquidation-module--thirdParagraph--2WulB";
export var fourthParagraph = "quickLiquidation-module--fourthParagraph--vuB8J";
export var orangeText = "quickLiquidation-module--orangeText--2o9_e";
export var guaranteesParagraph = "quickLiquidation-module--guaranteesParagraph--2AzQg";
export var guaranteesIcon = "quickLiquidation-module--guaranteesIcon--3ZIeM";
export var guaranteesText = "quickLiquidation-module--guaranteesText--XIy2R";
export var borderLine = "quickLiquidation-module--borderLine--3KOO3";
export var rightContainer = "quickLiquidation-module--rightContainer--atfd3";
export var imageContainer = "quickLiquidation-module--imageContainer--1hPGz";
export var greenBackground = "quickLiquidation-module--greenBackground--3ZTnC";
export var girlImg = "quickLiquidation-module--girlImg--3GS6D";
export var gridImg = "quickLiquidation-module--gridImg--2ot2F";
export var gridImgMobile = "quickLiquidation-module--gridImgMobile--3ciwT";
export var quote = "quickLiquidation-module--quote--1d7t9";
export var quoteIcon = "quickLiquidation-module--quoteIcon--1n-zh";
export var quoteText = "quickLiquidation-module--quoteText--3gn2f";
export var quoteLine = "quickLiquidation-module--quoteLine--1ibF3";