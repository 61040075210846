import React from "react"
import PropTypes from "prop-types"
import Card from "./components/Card"
import CardRightText from "./components/Card/CardRightText"
import * as styles from "./cardsSection.module.scss"

const CardsSection = ({
                        title,
                        cardsCheckboxItems,
                        paragraph,
                        cardsSmallText,
                        cards,
                        isBuyPage,
                        imageLeft,
                        imageRight,
                        cardsOrangeCardTitle,
                        cardsOrangeCardListItems,
                        cardsButtonText,
                        cardsButtonUrl,
                        cardsTextNextToButton,
  }) => {

  return (
    <div className={styles.containerBg}>
      <div className={styles.contentContainer}>
        <div className={isBuyPage ? styles.gridSvgBuy : styles.gridSvg} />

        <CardRightText
          isBuyPage={isBuyPage}
          title={title}
          cardsCheckboxItems={cardsCheckboxItems}
          paragraph={paragraph}
          cardsSmallText={cardsSmallText}
          imageLeft={imageLeft}
          imageRight={imageRight}
        />
        <Card
          isBuyPage={isBuyPage}
          buttonText={cardsButtonText}
          buttonLink={cardsButtonUrl}
          cards={cards}
          cardsOrangeCardTitle={cardsOrangeCardTitle}
          cardsOrangeCardListItems={cardsOrangeCardListItems}
          cardsButtonText={cardsButtonText}
          cardsButtonUrl={cardsButtonUrl}
          cardsTextNextToButton={cardsTextNextToButton}
        />
      </div>
    </div>
  )
}

CardsSection.defaultProps = {
  isBuyPage: false,
}

CardsSection.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string,
  imageLeft: PropTypes.string,
  imageRight: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ),
  isBuyPage: PropTypes.bool,
}

export default CardsSection
