// extracted by mini-css-extract-plugin
export var card = "card-module--card--2VsXB";
export var checkbox = "card-module--checkbox--2OS7W";
export var checkboxImage = "card-module--checkboxImage--IRDKh";
export var text = "card-module--text--1dcXZ";
export var buttonContainer = "card-module--buttonContainer--Jege0";
export var buttonBuy = "card-module--buttonBuy--qioz1";
export var button = "card-module--button--2V8EH";
export var rightTextBuyPage = "card-module--rightTextBuyPage--6hjyg";
export var title = "card-module--title--2rK4v";
export var paragraph = "card-module--paragraph--3Pz_S";
export var plusIcon = "card-module--plusIcon--1T9Jj";
export var svg1Color = "card-module--svg1Color--397Ac";
export var svg2Color = "card-module--svg2Color--3_Z-d";
export var svg3Color = "card-module--svg3Color--3dtLc";
export var svg4Color = "card-module--svg4Color--2hMTF";
export var svg6Color = "card-module--svg6Color--sUPlq";
export var svgOutline = "card-module--svgOutline--3fH-e";
export var subtitle = "card-module--subtitle--1ZEF8";
export var border = "card-module--border--FV7ot";