import React from 'react';
import backgroundGreen from '/static/assets/green-background-svg.svg'
import grid from '../../../templates/avveckla-aktiebolag/assets/images/gridRepeatGrey.svg'
import gridHorizontal from '/static/assets/repeat-grid-horizontal.svg'
import greenCheck from '/static/assets/green-check.svg'
import quote from '/static/assets/greenQuote.svg'
import NewlineText from '../../../utils/NewlineText'
import * as styles from './quickLiquidation.module.scss';

const QuickLiquidation = ({
                            quickLiquidationImage,
                            quickLiquidationTitle,
                            quickLiquidationFirstParagraph,
                            quickLiquidationGreenText,
                            quickLiquidationThirdParagraph,
                            quickLiquidationFourthParagraph,
                            quickLiquidationOrangeText,
                            quickLiquidationQuote,
                            quickLiquidationGuarantees
    }) => {

    return (
        <div className={styles.quickLiquidationBackground}>
            <div className={styles.quickLiquidationContainer}>
                <div className={styles.leftContainer}>
                    <div className={styles.title}>
                        {quickLiquidationTitle}
                    </div>
                    <div className={styles.borderLine} />
                    <div className={styles.firstParagraph}>
                        {quickLiquidationFirstParagraph}
                    </div>
                    <div className={styles.greenParagraph}>
                        {quickLiquidationGreenText}
                    </div>
                    {quickLiquidationThirdParagraph &&
                        <div className={styles.thirdParagraph}>
                            {quickLiquidationThirdParagraph}
                        </div>}
                    {quickLiquidationFourthParagraph &&
                        <div className={styles.fourthParagraph}>
                            {quickLiquidationFourthParagraph}
                        </div>}
                    <div className={styles.orangeText}>{quickLiquidationOrangeText}</div>
                    {quickLiquidationGuarantees && quickLiquidationGuarantees.map((itm, index) => (
                        <div className={styles.guaranteesParagraph} key={index}>
                            <div className={styles.guaranteesIcon}>
                                <img src={greenCheck} alt="greenCheck" />
                            </div>
                            <div className={styles.guaranteesText}>
                                {itm.guarantee}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.imageContainer}>
                        <div >
                            <img src={backgroundGreen} className={styles.greenBackground} alt="background" />
                        </div>
                        <div >
                            <img src={grid} alt="grid" className={styles.gridImg} />
                            <img src={gridHorizontal} alt="gridHorizontal" className={styles.gridImgMobile} />
                        </div>
                        <div >
                            <img src={quickLiquidationImage} alt="rightImg" className={styles.girlImg} />
                        </div>
                    </div>
                    <div className={styles.quote}>
                        <div className={styles.quoteIcon} >
                            <img src={quote} alt="quote" />
                        </div>
                        <div className={styles.quoteText}>
                            <NewlineText
                                text={quickLiquidationQuote}
                                style={styles.quoteLine} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickLiquidation;
