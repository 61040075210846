// extracted by mini-css-extract-plugin
export var mainContainer = "cardRightText-module--mainContainer--2ZhZu";
export var isBuyPage = "cardRightText-module--isBuyPage--3k_1B";
export var cardRightTitle = "cardRightText-module--cardRightTitle--33EmV";
export var cardRightBorder = "cardRightText-module--cardRightBorder--KKcey";
export var cardRightContent = "cardRightText-module--cardRightContent--3jk5X";
export var mobileOrangeTop = "cardRightText-module--mobileOrangeTop--3lqOU";
export var mobileOrangeTopTextContainer = "cardRightText-module--mobileOrangeTopTextContainer--2lF3m";
export var mobileOrangeTopTitle = "cardRightText-module--mobileOrangeTopTitle--1ie9I";
export var mobileOrangeTopGrayLine = "cardRightText-module--mobileOrangeTopGrayLine--fUC5s";
export var mobileOrangeTopParagraph = "cardRightText-module--mobileOrangeTopParagraph--30YGD";
export var buttonWrapper = "cardRightText-module--buttonWrapper--2uPDN";
export var sellLogosTablet = "cardRightText-module--sellLogosTablet--FVVTK";
export var logoWrapper = "cardRightText-module--logoWrapper--jfauL";
export var sellImg = "cardRightText-module--sellImg--oIqoP";
export var estImg = "cardRightText-module--estImg--1O_iO";
export var content = "cardRightText-module--content--3HNls";
export var text = "cardRightText-module--text--dqbPA";
export var paragraphContent = "cardRightText-module--paragraphContent--3-JcM";
export var cardRightContentBuy = "cardRightText-module--cardRightContentBuy--3yjiO";