// extracted by mini-css-extract-plugin
export var servicesBackground = "services-module--servicesBackground--coAUY";
export var servicesContainer = "services-module--servicesContainer--3IGEM";
export var leftContainer = "services-module--leftContainer--13lAt";
export var backgroundSvg = "services-module--backgroundSvg--Y98AS";
export var leftTitle = "services-module--leftTitle--17ZYS";
export var borderLine = "services-module--borderLine--38RAl";
export var imageWrapper = "services-module--imageWrapper--3rQ7g";
export var leftImg = "services-module--leftImg--IaA0K";
export var playImage = "services-module--playImage--9uzNu";
export var playImageDesktop = "services-module--playImageDesktop--kq7rO";
export var bottomTextContainer = "services-module--bottomTextContainer--Trp7l";
export var bottomText = "services-module--bottomText--2EJZe";
export var bottomRightWrapper = "services-module--bottomRightWrapper--pVIyC";
export var bottomRightText = "services-module--bottomRightText--3Io2a";
export var rightContainer = "services-module--rightContainer--1nV-w";
export var rightText = "services-module--rightText--9Qub0";
export var checkSvg = "services-module--checkSvg--2u3d1";
export var buttonContainer = "services-module--buttonContainer--1UiWy";
export var buttonRightText = "services-module--buttonRightText--2Td7h";
export var buttonRightTextQuentingImageContainer = "services-module--buttonRightTextQuentingImageContainer--1vQ-2";
export var imageNextToButton = "services-module--imageNextToButton--_wqTG";