import React from 'react';

const NewlineText = (props) => {
  if(props.text) {
    return props.text.split('\n').map((str, index) => <div key={index} className={props.style}>{str}</div>)
  }

    return '';
  }

export default NewlineText;
