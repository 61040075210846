// extracted by mini-css-extract-plugin
export var backgroundLayer = "multiLayer-module--backgroundLayer--1q68A";
export var darkGreenTriangle = "multiLayer-module--darkGreenTriangle--cuY77";
export var backgroundImg = "multiLayer-module--backgroundImg--2ywKL";
export var contentContainer = "multiLayer-module--contentContainer--3QcKI";
export var testimonialsContainer = "multiLayer-module--testimonialsContainer--3_8_x";
export var leftContainer = "multiLayer-module--leftContainer--3zR71";
export var imageWrapper = "multiLayer-module--imageWrapper--10Goy";
export var leftGirl = "multiLayer-module--leftGirl--1cvjO";
export var leftMan = "multiLayer-module--leftMan--38zLh";
export var rightText = "multiLayer-module--rightText--22qoL";
export var textWrapper = "multiLayer-module--textWrapper--1FYmH";
export var quotesLeft = "multiLayer-module--quotesLeft--1TO9T";
export var quotesRight = "multiLayer-module--quotesRight--21A4q";
export var ucSigillLogo = "multiLayer-module--ucSigillLogo--1S4Wc";