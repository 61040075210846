import React from 'react';
import PropTypes from "prop-types";
import * as styles from './whycbs.module.scss';

const WhyCBS = ({ whyCbsTitle, whyCbsSubtitle, whyCbsCardsContentList, isBuyPage, isPreview }) => {

    return (
        <div className={styles.whycbsBackground}>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    {isBuyPage ?
                        <React.Fragment>
                            <div className={styles.subtitle}>
                                {whyCbsSubtitle}
                            </div>
                            <div className={styles.title}>
                                {whyCbsTitle}
                            </div>
                        </React.Fragment> :
                        <React.Fragment>
                            <div className={styles.title}>
                                {whyCbsTitle}
                            </div>
                            <div className={styles.subtitle}>
                                {whyCbsSubtitle}
                            </div>
                        </React.Fragment>
                    }
                    <div className={styles.borderLine} />
                </div>
                <div className={styles.contentContainer}>
                    {whyCbsCardsContentList && whyCbsCardsContentList.map((itm, index) => (
                        <div className={styles.itemContainer} key={index}>
                            <div className={styles.imageWrapper}>
                                <img
                                  src={isPreview ? itm.icon : itm.icon.childImageSharp.fluid.src}
                                  alt={itm.title}
                                  className={styles.cardImage}
                                />
                            </div>
                            <div className={styles.itemTitle}>
                                {itm.title}
                            </div>
                            <div className={styles.itemText}>
                                {itm.content}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}


WhyCBS.propTypes = {
  whyCBSMock: PropTypes.object,
  whyCbsTitle: PropTypes.string,
  whyCbsSubtitle: PropTypes.string,
}

export default WhyCBS;
