import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'gatsby'
import info from '/static/assets/infoIcon.svg'
import Arrow from '../CardsSection/components/Card/Svgs/Svg7'
import * as styles from './moreInfo.module.scss';

const MoreInformation = ({ moreInfoTitle, moreInfoSubtitle, moreInfoInfos }) => {

    return (
        <div className={styles.infoBackground}>
            <div className={styles.infoContainer}>
                <div className={styles.infoIcon}>
                    <img src={info} alt="info" width="100%" />
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.title}>
                        {moreInfoTitle}
                    </div>
                    <div className={styles.subtitle}>
                        {moreInfoSubtitle}
                    </div>
                    <div className={styles.borderLine} />
                    {moreInfoInfos && moreInfoInfos.map((info, index) => (
                        <Link to={info.link} className={styles.infoLink} key={index}>
                            <div className={styles.textContainer} >
                                <div className={styles.infoText}>
                                    {info.info}
                                </div>
                                <div className={styles.textIcon}>
                                    <Arrow svgColor={styles.svgColor} />
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

MoreInformation.propTypes = {
    moreInfoMock: PropTypes.object
}

export default MoreInformation;
