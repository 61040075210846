import React, { useState } from "react"
import PropTypes from "prop-types"
import ModalVideo from "react-modal-video"
import CustomButton from "../../CustomButton"
import check from "/static/assets/check.svg"
import background from "/static/assets/services-background.svg"
import playCircle from "/static/assets/play-circle.svg"
import * as styles from "./services.module.scss"
import "react-modal-video/scss/modal-video.scss"

const Services = ({
                    servicesTitle,
                    servicesVideoThumbnail,
                    servicesBottomText,
                    servicesServicesList,
                    servicesButtonText,
                    servicesButtonUrl,
                    servicesTextNextToButton,
                    servicesImageNextToButton,
                    videoId
    }) => {
    const [isOpen, setOpen] = useState(false)

    const videoFullUrl = "https://www.youtube.com/watch?v=" + videoId

    return (
        <div className={styles.servicesBackground}>
            <div className={styles.servicesContainer}>
                <div className={styles.leftContainer}>
                    <div>
                        <img
                            src={background}
                            alt="background"
                            className={styles.backgroundSvg}
                        />
                    </div>
                    <div className={styles.leftTitle}>{servicesTitle}</div>
                    <div className={styles.borderLine} />
                    <div className={styles.imageWrapper}>
                        <img src={servicesVideoThumbnail} alt="" className={styles.leftImg} />
                        <a href={videoFullUrl} target="_blank" rel="noreferrer">
                            <img className={styles.playImage} src={playCircle} alt="play" />
                        </a>
                        <div role="button" tabIndex={0} onClick={() => setOpen(true)} onKeyDown={() => setOpen(true)}>
                            <img
                                className={styles.playImageDesktop}
                                src={playCircle}
                                alt="play"
                            />
                        </div>
                    </div>
                    <div className={styles.bottomTextContainer}>
                        <div className={styles.bottomText}>{servicesBottomText}</div>
                        <ModalVideo
                            channel="youtube"
                            autoplay
                            isOpen={isOpen}
                            videoId={videoId}
                            onClose={() => setOpen(false)}
                        />
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    {servicesServicesList && servicesServicesList.map((service, index) => (
                        <div className={styles.rightText} key={index}>
                            <img src={check} alt="check" className={styles.checkSvg} />
                            {service.content}
                        </div>
                    ))}
                    <div className={styles.buttonContainer}>
                        <CustomButton
                            link={`${process.env.GATSBY_HOST_URL}${servicesButtonUrl}`}
                            text={servicesButtonText}
                            isBuySell= {true}
                            lightGreen={true}
                            fullWidth = {true}
                            externalLink
                        />

                        <div>
                          <span className={styles.buttonRightText}>{servicesTextNextToButton}</span>
                          <div className={styles.buttonRightTextQuentingImageContainer}>
                            <img src={servicesImageNextToButton} alt={servicesTextNextToButton} className={styles.imageNextToButton} />
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Services.propTypes = {
    servicesMock: PropTypes.object,
}

export default Services
