// extracted by mini-css-extract-plugin
export var infoBackground = "moreInfo-module--infoBackground--3zlvT";
export var infoContainer = "moreInfo-module--infoContainer--64p6N";
export var infoIcon = "moreInfo-module--infoIcon--o_KJj";
export var contentContainer = "moreInfo-module--contentContainer--3lkbV";
export var title = "moreInfo-module--title--3Ntz7";
export var subtitle = "moreInfo-module--subtitle--1Oe_2";
export var borderLine = "moreInfo-module--borderLine--1snjV";
export var infoLink = "moreInfo-module--infoLink--3BKUw";
export var textContainer = "moreInfo-module--textContainer--2O7Zk";
export var textIcon = "moreInfo-module--textIcon--1B3ai";
export var svgColor = "moreInfo-module--svgColor--3EQR5";
export var infoText = "moreInfo-module--infoText--3PB0C";